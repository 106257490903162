export enum BankSecurityProps {
  BANK = 'bank',
  NAME = 'name',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  POSTAL_CODE = 'postalCode',
  COUNTRY_CODE = 'countryCode',
  TYPE_OF_BANK = 'typeOfBank',
  EFFECTIVE_DATE = 'effectiveDate',
  EXPIRY_DATE = 'expiryDate',
  MOODY_RATE_CODE = 'moodyRateCode',
  SANDP_RATE_CODE = 'sandPRateCode',
  FUNDING_VALIDATOR_INDICATOR = 'fundingValidIndicator',
  SECURITY_VALIDATOR_INDICATOR = 'securityValidIndicator'
}

export enum DistributionBodiesProps {
  BANKS = 'banks',
  RATINGS = 'ratings',
  ALL_RATINGS = 'allRatings',
  BANK = 'bank'
}

export enum BankSecurityFilterProps {
  BANK = 'bank',
  NAME = 'name',
  COUNTRY_CODE = 'countryCode',
  TYPE_OF_BANK = 'typeOfBank',
  EFFECTIVE_DATE_FROM = 'effectiveDateFrom',
  EFFECTIVE_DATE_TO = 'effectiveDateTo',
  EXPIRY_DATE_FROM = 'expiryDateFrom',
  EXPIRY_DATE_TO = 'expiryDateTo',
  MOODY_RATE_CODE = 'moodyRateCode',
  SANDP_RATE_CODE = 'sandPRateCode'
}

export enum BankSecurityTestIds {
  HEADER = 'bank-security__header',
  FILTER_TOOLBAR = 'bank-security__filter-toolbar',
  TABLE = 'bank-security__table',
  EXPANDIBLE = 'bank-security__expandible-form'
}

export enum BankSecurityRatingsTestIds {
  SUBMIT_CANCEL = 'bank-security-ratings__submit-cancel'
}

export enum MoodysRatingsProps {
  INVESTMENT_GRADE = 'investmentGrade',
  MOODY_RATING = 'moodyRating'
}

export enum SPsRatingProps {
  INVESTMENT_GRADE = 'investmentGrade',
  SANDP_RATING = 'sandpRating'
}

export enum BankType {
  FUNDING = 'funding',
  SECURITY = 'security'
}
